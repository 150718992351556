var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('layout',{attrs:{"width":"420"}},[_c('template',{slot:"header"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),[_c('v-form',[_c('validation-provider',{attrs:{"name":"Item name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Item name","error-messages":errors},model:{value:(_vm.state.name),callback:function ($$v) {_vm.$set(_vm.state, "name", $$v)},expression:"state.name"}},[_vm._v(" "+_vm._s(_vm.state.name)+" ")])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Variable type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('type-selector',{attrs:{"label":"Variable type","error-messages":errors},model:{value:(_vm.state.type),callback:function ($$v) {_vm.$set(_vm.state, "type", $$v)},expression:"state.type"}})]}}],null,true)}),_c('v-text-field',{attrs:{"label":"Units"},model:{value:(_vm.state.units),callback:function ($$v) {_vm.$set(_vm.state, "units", $$v)},expression:"state.units"}},[_vm._v(" "+_vm._s(_vm.state.units)+" ")]),_c('v-text-field',{attrs:{"label":"Mask"},model:{value:(_vm.state.mask),callback:function ($$v) {_vm.$set(_vm.state, "mask", $$v)},expression:"state.mask"}},[_vm._v(" "+_vm._s(_vm.state.mask)+" ")]),_c('v-textarea',{attrs:{"no-resize":"","rows":"1","auto-grow":"","label":"Description"},model:{value:(_vm.state.description),callback:function ($$v) {_vm.$set(_vm.state, "description", $$v)},expression:"state.description"}},[_vm._v(" "+_vm._s(_vm.state.description)+" ")]),_c('validation-provider',{attrs:{"name":"Alert count","rules":_vm.maxAlertCount},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Alert count","error-messages":errors},model:{value:(_vm.state.alertCount),callback:function ($$v) {_vm.$set(_vm.state, "alertCount", $$v)},expression:"state.alertCount"}},[_vm._v(" "+_vm._s(_vm.state.alertCount)+" ")])]}}],null,true)})],1)],_c('template',{slot:"footer"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"text-primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":invalid,"depressed":"","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.submit.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.submitBtnTitle)+" ")])],1)],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }